import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useReactiveVar } from "@apollo/client";
import { useRouter } from "next/router";
import clsx from "clsx";
import Image from "next/image";

import useNotificationsSubscription from "@/utils/hooks/useNotificationsSubscription";
import useDisplayUserState from "@/utils/hooks/useDisplayUserState";
import { Currency, MeDocument } from "@/graphql/types";
import { useThrottle } from "@/utils/hooks";
import { useWindowSize } from "@/components/WindowSizeProvider";
import FeatureFlagOnly from "@/components/hoc/FeatureFlagOnly";
import { getPosthog, useGetPosthog } from "@/utils/analytics";
import { identifyMe } from "@/utils/analytics/identifyMe";
import SearchInput from "@/components/navbar/SearchInput";
import Notifications from "@/components/notifications";
import { hasAccessTokenVar } from "@/apollo/links/auth";
import ClientOnly from "@/components/hoc/ClientOnly";
import { getCurrencyCode } from "@/utils/currencies";
import { ChatContext } from "@/utils/reactContexts";
import { LS_REFERRAL_CODE } from "@/pages/_app";
import Link from "@/components/hoc/CustomLink";
import ConnectButtons from "@/components/navbar/ConnectButtons";
import NavigationDropdown from "@/components/navbar/NavigationDropdown";
import { saveAuthUser } from "@/utils/oAuth/storage";

function Navbar() {
  const router = useRouter();
  const isLogged = useReactiveVar(hasAccessTokenVar);
  const { t } = useTranslation("common");
  const { width } = useWindowSize();
  const isMobile = width < 1024;
  const { rc: referralCode } = router.query;

  const [savedY, setSavedY] = useState(0);
  const [showNavbar, setShowNavbar] = useState(false);

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const { posthog, status } = useGetPosthog();
  const { setShowChat } = useContext(ChatContext);

  const variables = { currency: getCurrencyCode() as string as Currency };
  const { data, loading } = useQuery(MeDocument, {
    skip: !isLogged,
    variables,
  });
  useDisplayUserState(data?.me);

  useNotificationsSubscription();

  useEffect(() => {
    if (data) saveAuthUser(data.me);
  }, [data]);

  useEffect(() => {
    const code = typeof referralCode === "object" ? referralCode[0] : referralCode;
    if (data && data.me.referralCode) {
      getPosthog()?.register({
        referralCode: data.me.referralCode,
      });
    } else if (code && code !== "undefined") {
      localStorage.setItem(LS_REFERRAL_CODE, code);
      getPosthog()?.register({
        referralCode: code,
      });
    }
  }, [referralCode, data]);

  const scrollCallback = useThrottle(() => {
    if (savedY > window.scrollY) {
      setShowNavbar(true);
    } else if (savedY < window.scrollY) {
      setShowNavbar(false);
    }
    setSavedY(window.scrollY);
  }, 100);

  useEffect(() => {
    if (typeof window !== undefined) {
      window.addEventListener("scroll", scrollCallback);

      return () => {
        window.removeEventListener("scroll", scrollCallback);
      };
    }

    return undefined;
  }, [scrollCallback]);

  useEffect(() => {
    const root = document.documentElement;
    if (showNavbar) {
      if (savedY <= 50) root.style.setProperty("--toast-container-top", isMobile ? "48px" : "96px");
      else root.style.setProperty("--toast-container-top", isMobile ? "64px" : "96px");
    } else if (savedY <= 50) {
      root.style.setProperty("--toast-container-top", (isMobile ? 64 : 100) - savedY + "px");
    } else {
      root.style.setProperty("--toast-container-top", "16px");
    }
  }, [showNavbar, savedY, isMobile]);

  useEffect(() => {
    if (!posthog || status !== "ready") return;

    if (data) {
      // Identify for PostHog
      identifyMe(data.me);
    }

    posthog?.register({
      "Logged In": !!data?.me.id,
      "Email Verified": !!data?.me.isEmailVerified,
    });
  }, [posthog, status, data]);

  // Note: memoize notifications component to avoid re-rendering on scroll
  const navbarIsTop = savedY <= 50 || !showNavbar;
  const notifications = useMemo(() => {
    if (!data) return null;

    return (
      <Notifications
        me={data?.me}
        isMobile={isMobile}
        setShowMobileMenu={setShowMobileMenu}
        navbarIsTop={navbarIsTop}
      />
    );
  }, [data, isMobile, navbarIsTop]);

  // Dashboard has its own navbar
  if (router.asPath.includes("/dashboard")) return null;

  return (
    <header
      className={clsx(
        "top-0 w-full left-0 flex flex-col z-1000 transition",
        "ease-quart-out duration-300 mt-0",
        showNavbar && savedY > 50
          ? "fixed bg-dark-2/40 before:absolute before:inset-0 before:-z-10 before:backdrop-blur-lg"
          : "absolute"
      )}
    >
      <div className={clsx("container my-5 text-white", (!showNavbar || savedY <= 50) && "lg:my-8")}>
        <div className="flex items-center justify-between">
          <div className="flex items-baseline lg:flex-shrink-0 min-w-0">
            <Link href="/">
              <a className="mr-8 lg:mr-10 xl:mr-13 relative h-[13px] lg:h-5.5 w-[100px] lg:w-42.5">
                <Image
                  src="/assets/logo/pianity-white.svg"
                  alt="logo"
                  className="cursor-pointer"
                  layout="fill"
                  sizes="256px"
                />
              </a>
            </Link>

            <NavbarLinks />

            <FeatureFlagOnly flag="feature-chat">
              <button
                onClick={() => {
                  if (isLogged) setShowChat(true);
                  else {
                    const query: { [key in string]: string } = {
                      redirect: encodeURIComponent(router.asPath),
                      msg: "clubs",
                    };
                    if (typeof router.query.rc === "string") {
                      query.rc = router.query.rc;
                    }
                    router.push({ pathname: "/connect", query });
                  }
                }}
                className={clsx(
                  "hidden lg:block text-base leading-1 font-medium",
                  "tracking-wider uppercase cursor-pointer transition",
                  "ease-quart-out duration-300 lg:hover:text-purple-2"
                )}
              >
                {t("common.navbar.links.chat")}
              </button>
            </FeatureFlagOnly>
          </div>

          <div className="flex items-center justify-end min-w-0">
            <SearchInput />
            {data?.me && notifications}
            {!router.asPath.includes("/connect") && (
              <ClientOnly>
                <ConnectButtons
                  me={data?.me}
                  meLoading={loading}
                  showMobileMenu={showMobileMenu}
                  setShowMobileMenu={setShowMobileMenu}
                />
              </ClientOnly>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}

function NavbarLinks() {
  const { t } = useTranslation("common");
  const isLogged = useReactiveVar(hasAccessTokenVar);

  return (
    <>
      <FeatureFlagOnly flag="personal-feed">
        {isLogged && (
          <Link href="/feed">
            <a
              className={clsx(
                "hidden lg:block mr-4 xl:mr-5 text-base leading-1 font-medium",
                "tracking-wider uppercase cursor-pointer transition",
                "ease-quart-out duration-300 lg:hover:text-purple-2"
              )}
            >
              {t("common.navbar.links.feed")}
            </a>
          </Link>
        )}
      </FeatureFlagOnly>

      <NavigationDropdown
        href="/market"
        label={t("common.navbar.links.market")}
        items={[
          { href: "/market", label: t("common.navbar.links.active") },
          { href: "/market/upcoming", label: t("common.navbar.links.upcoming") },
        ]}
      />

      <NavigationDropdown
        href="/activity"
        label={t("common.navbar.links.activity")}
        items={[
          { href: "/activity", label: t("common.navbar.links.history") },
          { href: "/leaderboards", label: t("common.navbar.links.leaderboards") },
          { href: "/stats", label: t("common.navbar.links.stats") },
        ]}
      />
    </>
  );
}

export default Navbar;
